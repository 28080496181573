import { Component, OnInit,Renderer2 } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { parseHTML } from 'cheerio/lib/static';

declare var $: any;
@Component({
  selector: 'app-live-matches',
  templateUrl: './live-matches.component.html',
  styleUrls: ['./live-matches.component.css']
})
export class LiveMatchesComponent implements OnInit {

  livedMatchesList: any;
  isLive:boolean = false;

  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router) { 
    this.livedMatchesList =[];
    this.isLive = false;
  }

  title = 'OnlyScore';

  topFunction() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngOnInit(): void {
    this.getLiveMatchesList();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    setInterval(()=>{this.getLiveMatchesList();},9000);

    this.topFunction();
  }

  getLiveMatchesList(){
    this.scoreApi.getGameList().subscribe((result:any)=>{
      let listmatches = result.cl;
      let words = result.cl;
      let updat = words.filter((word: { isrun: string; }) => word.isrun === "true");
      if(updat.length > 0){this.isLive =true;}
      this.livedMatchesList= updat;
    });
  }

  gotoCommentry(eventID:any){
    localStorage.setItem('EventID',eventID);
    this.router.navigateByUrl('/commentry');
    return true;
  }

}