<!-- Breadcrumb Section Begin -->
<section class="breadcrumb-section set-bg-wallpaper" data-setbg="assets/img/breadcrumb-bg.jpg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="bs-text">
                    <h2>News</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Breadcrumb Section End -->
<!-- Blog Section Begin -->
<section class="blog-section spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 left-blog-pad">
                <div class="blog-items" id="newsDiv">
                    <div class="single-item boxshadowlight" *ngFor="let newsr of news_Articles">
                        <div class="bi-pic nav-news" (click)="gotoDetails(newsr.mainUrl)">
                            <img class="mobileNewimage nav-news" src="{{newsr.img}}" alt="{{newsr.til}}">
                        </div>
                        <div class="">
                            <h5 class="paddingtop4 bfcolorGrey" style="margin-left: 12px;"><a [routerLink]="" class="foottertitle nav-news" (click)="gotoDetails(newsr.mainUrl)">{{newsr.til}}</a></h5>
                            <p>{{newsr.cont}} </p>
                            <p>{{newsr.scon}} <small> <a class="moreless-button nav-news" routerLink="" style="color: blue;" (click)="gotoDetails(newsr.mainUrl)">Read more</a></small> </p>
                            <ul>
                                <li style="color:#333" class="mobi-newsDatetime"> <i class="fa fa-clock-o"></i> {{newsr.dateTime}}: by cricbuzz</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="more-blog flotleft d-none">
                    <!-- <a>Load More <i class="fa fa-arrow-down" aria-hidden="true" ></i></a> -->
                    <div id="loading"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <app-series-list></app-series-list>
            </div>
        </div>
    </div>
</section>
<!-- Blog Section End -->
<app-footer></app-footer>