import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfigService, initData } from '../services/config.service';
@Injectable({
  providedIn: 'root'
})
export class ScoreapiService {

  constructor(private httpclient:HttpClient,private config:ConfigService) {

  }

  getLiveMatchesList() : Observable<any> {
    return this.httpclient.get(initData.Score_API +  '/score/liveMatchesList');
  }

  getScheduleMatchesList() : Observable<any> {
    return this.httpclient.get(initData.Score_API +  '/score/scheduleMatchesList');
  }
  getGameList() : Observable<any> {
    return this.httpclient.get(initData.Score_API +  '/score/gameList');
  }
  getCompleteMatchesList() : Observable<any> {
    return this.httpclient.get(initData.Score_API +  '/score/completeMatchesList');
  }

  getSeriesList() : Observable<any> {
    return this.httpclient.get(initData.Score_API +  '/score/seriesList');
  }

  getFullScore(eventID:any) : Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify({"eventid": eventID });
    return this.httpclient.post(initData.Score_API +  '/score/fullScorecard', body,{'headers':headers});
  }

  getScore(eventID:any) : Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify({"eventid": eventID });
    return this.httpclient.post(initData.Score_API +  '/score/getScore', body,{'headers':headers});
  }


  getSquadsPlayers(eventID:any) : Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify({"eventid": eventID });
    return this.httpclient.post(initData.Score_API +  '/score/squadsPlayers', body,{'headers':headers});
  }

  getSportsNews() : Observable<any> {
    return this.httpclient.get(initData.Score_API +  '/score/getCricNews');
  }

  getNextPageNews(Url:any) : Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify({"url": Url });
    return this.httpclient.post(initData.Score_API +  '/score/loadNextPageCrickNews', body,{'headers':headers});
  }

  getNewsArticle(Url:any) : Observable<any> {
    const headers = { 'content-type': 'application/json'}  
    const body=JSON.stringify({"url": Url });
    return this.httpclient.post(initData.Score_API +  '/score/getArticle', body,{'headers':headers});
  }

  GetLatestNewsFromCric() : Observable<any> {
    return this.httpclient.get('https://newsapi.org/v2/top-headlines?country=in&category=sports&apiKey=bbd8cc8b8fd7441dafb587bf0aa5cd67', {responseType: "text"})
  }



}
