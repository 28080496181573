<!-- Breadcrumb Section Begin -->
<section class="breadcrumb-section set-bg-wallpaper" data-setbg="assets/img/breadcrumb-bg.jpg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="bs-text">
                    <h2 class="pagetitle-h2">Results</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Breadcrumb Section End -->

<!-- Schedule Section Begin -->
<section class="schedule-section spad">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 left-blog-pad">
                <div class="schedule-text">
                    <h4 class="st-title d-none">Completed Matches</h4>
                    <div class="st-table" *ngFor="let completedMatche of completedMatchesList">
                        <table>
                            <tbody class="cursorPointer">
                                <tr (click)="gotoCommentry(completedMatche.eid)">
                                    <td class="left-team">
                                        <img src={{completedMatche.te1i}} alt={{completedMatche.te1n}} style="width:90px;height:85px;">
                                        <h4 class="margintop18">{{completedMatche.s1n}}</h4>
                                        <b><span>{{completedMatche.t1s}}</span></b>
                                    </td>
                                    <td class="st-option">
                                        <div class="so-text" style="font-size: medium;"><b>{{completedMatche.en}} </b></div>
                                        <div _ngcontent-vfg-c24="" class="vs-image-content-pages"><svg _ngcontent-vfg-c24="" width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-vfg-c24="" _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M0.613599 41.3664C0.274718 41.0164 0.274718 40.449 0.613599 40.0991L6.26718 34.2608C6.60606 33.9109 7.1555 33.9109 7.49438 34.2608C7.83326 34.6108 7.83326 35.1782 7.49438 35.5281L1.8408 41.3664C1.50192 41.7163 0.95248 41.7163 0.613599 41.3664Z" fill="#c5141f"></path><path _ngcontent-vfg-c24="" _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M35.348 7.73919C35.0091 7.38924 35.0091 6.82185 35.348 6.4719L41.0016 0.633645C41.3404 0.283695 41.8899 0.283694 42.2288 0.633645C42.5676 0.983596 42.5676 1.55098 42.2288 1.90093L36.5752 7.73919C36.2363 8.08914 35.6869 8.08914 35.348 7.73919Z" fill="#c5141f"></path><path _ngcontent-vfg-c24="" _ngcontent-ofp-c75="" d="M37.5518 19.5002C37.5518 28.82 30.4388 36.3751 21.6646 36.3751C12.8903 36.3751 5.77734 28.82 5.77734 19.5002C5.77734 10.1805 12.8903 2.62529 21.6646 2.62529C30.4388 2.62529 37.5518 10.1805 37.5518 19.5002Z" fill="#c5141f"></path><path _ngcontent-vfg-c24="" _ngcontent-ofp-c75="" d="M20.4233 14.0316L16.5233 22.469L12.6564 14.0316H10.3164L15.3947 24.969H17.519L22.5808 14.0316H20.4233Z" fill="#fff"></path><path _ngcontent-vfg-c24="" _ngcontent-ofp-c75="" d="M27.4079 25.1253C30.5944 25.1253 32.1046 23.6253 32.1046 21.8753C32.1046 17.8597 25.2173 19.3753 25.2173 17.0472C25.2173 16.219 25.9475 15.5628 27.7399 15.5628C28.7522 15.5628 29.8973 15.844 30.9429 16.4222L31.6233 14.8441C30.611 14.2191 29.1505 13.8753 27.7565 13.8753C24.5701 13.8753 23.0764 15.3753 23.0764 17.1409C23.0764 21.2034 29.9803 19.6565 29.9803 22.0159C29.9803 22.8284 29.2169 23.4378 27.4079 23.4378C25.9807 23.4378 24.5037 22.9378 23.5743 22.2346L22.8275 23.8128C23.79 24.594 25.599 25.1253 27.4079 25.1253Z" fill="#fff"></path></svg></div>
                                        <div class="so-text"><span>{{completedMatche.mtyp}}</span></div>
                                    </td>
                                    <td class="right-team">
                                        <img src={{completedMatche.te2i}} alt={{completedMatche.te2n}} style="width:90px;height:85px;">
                                        <h4 class="margintop18" style="float: none;">{{completedMatche.s2n}}</h4>
                                        <b><span>{{completedMatche.t2s}}</span></b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <app-latest-news></app-latest-news>
            </div>
        </div>
    </div>
</section>
<!-- Schedule Section End -->
<app-footer></app-footer>