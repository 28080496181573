import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { css } from 'cheerio/lib/api/css';
import { ConfigService, initData } from '../services/config.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  MatchList: any;
  liveMatchList: any;
  scheduleMatchesList: any;
  completeMatchesList: any;
  Matches: any;
  isLive: boolean = false;
  isNews: boolean = false;
  news_Articles: any;
  posts: any;
  constructor(
    private renderer: Renderer2,
    private scoreApi: ScoreapiService,
    private config: ConfigService,
    private router: Router,
    private httpclient: HttpClient,
    private datepipe: DatePipe
  ) {
    this.MatchList = [];
    this.liveMatchList = [];
    this.scheduleMatchesList = [];
    this.completeMatchesList = [];
    this.news_Articles = [];
  }

  title = 'PlaySta';
  word: any;
  getversion: any;
  topFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  ngOnInit() {
    this.getScheduleMatchesList();
    this.getCompleteMatchesList();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js?v=' + initData.Score_API_VERSION;
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    this.getSportsNews();

    this.topFunction();
    this.getLiveMatchesList();
  }
  getLiveMatchesList() {
    this.scoreApi.getGameList().subscribe((result: any) => {
      let listmatches = result.cl;

      let words = result.cl;
      let dat = words.filter(
        (word: { isrun: string }) => word.isrun === 'false'
      );
      let updat = words.filter(
        (word: { isrun: string }) => word.isrun === 'true'
      );
      for (let index = 0; index < dat.length; index++) {
        const getTime = dat[index].et;
        dat[index].et = this.tConvert(getTime);
        dat[index].et = dat[index].et
          .replace(':00', '')
          .replace(':01', '')
          .replace('PM', ' PM')
          .replace('AM', ' AM');
        //     const element = dat[index];
        //     const dateArr = element.ed.split("/");

        //     const newDate = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
        //     dat[index].ed = newDate +"-" +  dat[index].et;

        //     const conveted_date = new Date(dat[index].ed);
        //     debugger
        //     dat[index].ed = this.datepipe.transform(conveted_date, 'EEE, MMM d y-h:mm a');
        //     dat[index].et = this.datepipe.transform(conveted_date, 'h:mm a');

        //     dat[index].ed = dat[index].ed.split("-");

        //     dat[index].ed=  dat[index].ed[0];
      }

      this.MatchList = dat.reverse();

      if (updat.length > 0) {
        this.isLive = true;
      }
      this.liveMatchList = updat;
    });
  }
  tConvert(time: any) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  getScheduleMatchesList() {
    this.scoreApi.getScheduleMatchesList().subscribe((result: any) => {
      let listmatches = result.cl;
      this.scheduleMatchesList = listmatches;
    });
  }

  getCompleteMatchesList() {
    this.scoreApi.getCompleteMatchesList().subscribe((result: any) => {
      let listmatches = result.cl;
      this.completeMatchesList = listmatches;
      for (let index = 0; index < listmatches.length; index++) {
        const getTime = listmatches[index].et;
        listmatches[index].et = this.tConvert(getTime);
        listmatches[index].et = listmatches[index].et
          .replace(':00', '')
          .replace(':01', '')
          .replace('PM', ' PM')
          .replace('AM', ' AM');
      }
    });
  }

  gotoCommentry(eventID: any) {
    localStorage.setItem('EventID', eventID);
    this.router.navigateByUrl('/commentry');
    return true;
  }

  getSportsNews() {
    try {
      this.scoreApi.getSportsNews().subscribe((result: any) => {
        if (result.isSuccess) {
          this.isNews = true;
          this.news_Articles = result.nl;
          for (let index = 0; index < this.news_Articles.length; index++) {
            this.news_Articles[index].til = this.news_Articles[
              index
            ].til.replace('&nbsp;&#8226;&nbsp;', '-');
            this.news_Articles[index].img = this.news_Articles[
              index
            ].img.replace('https://www.cricbuzz.com', '');
          }
          this.news_Articles = this.news_Articles.slice(0, 4);
          return this.news_Articles.slice(0, 6);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  gotoDetails(url: any) {
    localStorage.setItem('newsArticle', url);
    this.router.navigateByUrl('/news-article');
    return true;
  }

  customOptions: any = {
    items: 3,
    dots: false,
    autoplay: false,
    margin: 0,
    loop: false,
    smartSpeed: 1200,
    nav: false,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      600: {
        items: 1,
        nav: false,
      },
      650: {
        items: 1,
        nav: false,
      },
      700: {
        items: 1,
        nav: false,
        loop: false,
      },
      750: {
        items: 1,
        nav: false,
        loop: false,
      },
      800: {
        items: 1,
        nav: false,
        loop: false,
      },
      850: {
        items: 1,
        nav: false,
        loop: false,
      },
      900: {
        items: 1,
        nav: false,
        loop: false,
      },
      1000: {
        items: 3,
        nav: false,
        loop: false,
      },
    },
  };

  newssliderOptins: any = {
    loop: true,
    nav: true,
    items: 1,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
    smartSpeed: 1200,
    autoHeight: false,
    autoplay: true,
    mouseDrag: false,
  };
}
