import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormate', pure: true
})
export class DateFormatePipe implements PipeTransform {
constructor(public datepipe: DatePipe) {}
  transform(date: any): any {
    var date1 = date.split('/');
    var newDate = date1[1] + '/' +date1[0] +'/' +date1[2];
    const someDate = new Date(newDate);
    return this.datepipe.transform(someDate, 'EEE, MMM d y');
  }

}