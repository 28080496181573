import { Component, AfterViewInit, OnInit, Renderer2  } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, AfterViewInit  {
  //constructor(private renderer: Renderer2){}
  constructor(private renderer: Renderer2,public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-P26VRJL2W0', { 'page_path': event.urlAfterRedirects });
      }      
    })
  }
  title = 'Playsta';
  ngOnInit(): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
  }
  ngAfterViewInit() {
   
  }
  
}
