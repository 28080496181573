import { Component, OnInit,Renderer2 } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-series-list',
  templateUrl: './series-list.component.html',
  styleUrls: ['./series-list.component.css']
})
export class SeriesListComponent implements OnInit {
  seriersList: any;
  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router) {
    this.seriersList= [];
   }

  ngOnInit(): void {
    this.getSeriesList();
  }

  getSeriesList(){
    this.scoreApi.getSeriesList().subscribe((result:any)=>{
      let listmatches = result.clst;
      listmatches = listmatches.reverse();
      listmatches = listmatches.slice(0, 12);
      this.seriersList= listmatches;
    });
  }

}
