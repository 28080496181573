import { Component, OnInit,Renderer2 } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-completed-matches',
  templateUrl: './completed-matches.component.html',
  styleUrls: ['./completed-matches.component.css']
})
export class CompletedMatchesComponent implements OnInit {
  completedMatchesList: any;
  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router) {
    this.completedMatchesList= []
   }

  title = 'OnlyScore';
  
  topFunction() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}

  ngOnInit(): void {
    this.getCompleteMatchesList();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);

    this.topFunction();
  }
  ngAfterViewInit() {
    // $('.owl-carousel').owlCarousel();
  }

  getCompleteMatchesList(){
    this.scoreApi.getCompleteMatchesList().subscribe((result:any)=>{
      let listmatches = result.cl;
      this.completedMatchesList= listmatches;
    });
  }

  gotoCommentry(eventID:any){
    localStorage.setItem('EventID',eventID);
    this.router.navigateByUrl('/commentry');
    return true;
  }

}
