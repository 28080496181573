import { Component, OnInit ,Renderer2,ViewChild } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-news-article',
  templateUrl: './news-article.component.html',
  styleUrls: ['./news-article.component.css']
})

export class NewsArticleComponent implements OnInit {
  isnewsArticke :boolean= false;

  news_Article:any;
  articleUrl:any;
  dateTime:any;
  cont:any;
  img:any;
  scon:any;
  til:any;
  headline:any;
  isArticle:boolean=false;

  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router,private httpclient:HttpClient) { 
    this.news_Article = [];
    this.articleUrl="";
    this.dateTime="";
    this.cont="";
    this.img="";
    this.scon="";
    this.til="";
    this.headline="";
  }

  ngOnInit(): void {
    this.articleUrl = localStorage.getItem('newsArticle')
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    this.getNewsArticle(this.articleUrl);
    this.topFunction();
  }

  topFunction() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  getNewsArticle(url:any){
    this.scoreApi.getNewsArticle(url).subscribe((result:any)=>{
      if(result.isSuccess){        
        this.isArticle = true;
        this.news_Article = result.ar;
        this.dateTime=this.news_Article.dateTime.substr(0, 15);
        this.headline=this.news_Article.headline.replace('&copy;','');
        this.cont= this.news_Article.cont;
        this.img=this.news_Article.img;
        this.scon=this.news_Article.scon.replace('&copy;','');
        this.til=this.news_Article.til.replace('&copy;','');
      }
    });
  }
}
