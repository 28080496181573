<!-- Footer Section Begin -->
<footer class="footer-section set-bg" data-setbg="../assets/img/footer-bg.jpg">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="fs-logo">
                    <div class="logo">
                        <a href="./index.html"><img class="footerLogo" src="assets/img/playsta-logo-dark.png" alt="Logo"></a>
                        <br/>
                        <a href="https://play.google.com/store/apps/details?id=com.code365.onlyscore" class="download-badge d-none" target="_blank"><img class="footerGoogleplay" src="assets/img/gplaycard.svg" alt="DownloadApp"></a>
                    </div>
                    <div class="fs-social">
                        <!-- <a href="javascript:void(0)" id="a_whatsapp"><i class="fa fa-whatsapp"></i></a>
                        <a href="#"><i class="fa fa-twitter"></i></a>
                        <a href="#"><i class="fa fa-tumblr"></i></a>
                        <a href="#"><i class="fa fa-linkedin"></i></a>
                        <a href="#"><i class="fa fa-instagram"></i></a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="fs-widget">
                    <h4 class="foottertitle">About Us</h4>
                    <div class="fw-item">
                        <h5>
                            <a href="javascript:void(0)">
                                You can Download Our App for Fast, Reliable Cricket Data and Live Score & Cricket Stats 
                                <br/>
                                The app features multiple live coverage of cricket matches with Scorecard, Fixtures, Players Teams and so on. It also includes in-depth statistics of every Cricket match and cricketers to have played the game.
                            </a>
                        </h5>
                    </div>
                    <div class="fw-item d-none">
                        <h5><a href="#">England win shows they have the spark to go far at World Cup</a></h5>
                        <ul>
                            <li><i class="fa fa-calendar"></i> May 19, 2019</li>
                            <li><i class="fa fa-edit"></i> 3 Comment</li>
                        </ul>
                    </div>
                    <div class="fw-item d-none">
                        <h5><a href="#">Sri Lanka v Australia: Cricket World Cup 2019 – live!</a></h5>
                        <ul>
                            <li><i class="fa fa-calendar"></i> May 19, 2019</li>
                            <li><i class="fa fa-edit"></i> 3 Comment</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-1">
                <div class="fs-widget">
                    <h4 class="foottertitle">Quick Links</h4>
                    <ul class="fw-links">
                        <li><a [routerLink]="['/home']">Home</a></li>
                        <li><a [routerLink]="['/livematches']">Live</a></li>
                        <li><a [routerLink]="['/schedulematches']">Upcoming</a></li>
                        <li><a [routerLink]="['/completedmatches']">Completed</a></li>
                        <li><a [routerLink]="[ '/cric-news']">News</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="copyright-option">
            <div class="row">
                <div class="col-lg-12">
                    <div class="co-text">
                        <p>
                            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                            Copyright &copy;
                            <script>
                                document.write(new Date().getFullYear());
                            </script> All rights reserved by <a href="/" class="foottertitle" style="font-weight: 900;">PLAYSTA</a>
                            <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
                        </p>
                    </div>
                    <div class="co-widget d-none">
                        <ul>
                            <li><a href="#">Copyright notification</a></li>
                            <li><a href="#">Terms of Use</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Section End -->

<a id="button"></a>