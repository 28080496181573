import { Component, OnInit, Renderer2 } from '@angular/core';

import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';

import * as signalR from '@microsoft/signalr';

declare var $: any;
@Component({
  selector: 'app-commentry',
  templateUrl: './commentry.component.html',
  styles: ['./commentry.component.css'],
})
export class CommentryComponent implements OnInit {
  title = 'Commentry';
  scoreurl: any;
  fullScoreBorard: any;

  teamDetails: any;
  battingTeam1: any;
  bowlingTeam1: any;
  fallofwkt1: any;

  battingTeam2: any;
  bowlingTeam2: any;
  fallofwkt2: any;
  eventid: any;
  isFirstinning: boolean = false;
  isMatcheStarted: boolean = false;
  teamname: any;
  setTag: any = 'tab1';
  setSubTag: any = 'tab-411';
  theamNo: number = 0;

  Uri: any = 'https://feedapi.onlyscore.live/score';

  teamName: any;
  teamScore: any;
  teamOver: any;

  team1Squad: any;
  team2Squad: any;
  team1Namesquad: any;
  team2Namesquad: any;

  _isConnect: boolean = false;

  teamStatusCode: any;
  CommentryDisplaySatus: any;
  teamshortName1: any;
  teamshortName2: any;
  teamName1: any;
  teamName2: any;
  matchTitle: any;
  timer: any;
  countDownDate: any;

  list = [
    {
      id: '1',
      name: 'Normal Dark',
    },
    {
      id: '2',
      name: 'Normal Light',
    },
    {
      id: '3',
      name: 'Transformer-1',
    },
    {
      id: '4',
      name: 'Transformer-2',
    },
    {
      id: '5',
      name: 'Blue Bubble',
    },
    {
      id: '6',
      name: 'Black Bubble',
    },
    {
      id: '7',
      name: 'Bubble Expand',
    },
    {
      id: '8',
      name: 'Green Grass',
    },
    {
      id: '9',
      name: 'Green ScoreBoard',
    },
    {
      id: '10',
      name: 'Black Shine',
    },
  ];

  constructor(
    private renderer: Renderer2,
    private scoreApi: ScoreapiService,
    private router: Router
  ) {
    this.fullScoreBorard = {};
    this.teamDetails = {};

    this.battingTeam1 = [];
    this.bowlingTeam1 = [];
    this.fallofwkt1 = [];

    this.battingTeam2 = [];
    this.bowlingTeam2 = [];
    this.fallofwkt2 = [];

    this.team1Squad = [];
    this.team2Squad = [];
    this.teamName = '';
    this.teamScore = '';
    this.teamOver = '';

    this.teamStatusCode = '';
    this.CommentryDisplaySatus = '';
    this.teamshortName1 = '';
    this.teamshortName2 = '';
    this.teamName1 = '';
    this.teamName2 = '';
    this.matchTitle = '';
    this.timer = '';
    this.countDownDate = '';
  }
  topFunction() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnInit() {
    let eventID = localStorage.getItem('EventID');

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);

    this.scoreurl =
      'https://scoredesign.onlyscore.live/scorebord?id=' +
      eventID +
      '&color=c5141f&font=fbfbfb';

    this.getFullScore(eventID);
    this.getScore(eventID);

    //this.getSocketConnect(this.Uri);
    this.topFunction();
    this.getSquadsPlayers(eventID);
    setInterval(() => {
      this.getFullScore(localStorage.getItem('EventID'));
    }, 9000);
  }

  getFullScore(eventID: any) {
    this.scoreApi.getFullScore(eventID).subscribe((result: any) => {
      let listmatches = result;
      this.fullScoreBorard = listmatches;
      this.teamDetails = this.fullScoreBorard.es;
      if (
        this.teamDetails.sts == 'First Inning Running' ||
        this.teamDetails.sts == 'First Inning Completed'
      ) {
        this.isMatcheStarted = true;
        this.isFirstinning = true;
        if (this.fullScoreBorard.bat1.length > 0) {
          this.teamname = this.fullScoreBorard.es.tn1;
          this.battingTeam1 = this.fullScoreBorard.bat1;
          if (this.fullScoreBorard.bow2.length > 0) {
            this.bowlingTeam1 = this.fullScoreBorard.bow2;
          }
          this.fallofwkt1 = this.fullScoreBorard.fow1;
        } else if (this.fullScoreBorard.bat2.length > 0) {
          this.teamname = this.fullScoreBorard.es.tn2;
          this.battingTeam1 = this.fullScoreBorard.bat2;
          if (this.fullScoreBorard.bow1.length > 0) {
            this.bowlingTeam1 = this.fullScoreBorard.bow1;
          }
          this.fallofwkt1 = this.fullScoreBorard.fow1;
        }
      } else if (
        this.teamDetails.sts == 'Second Inning Running' ||
        this.teamDetails.sts == 'Second Inning Completed' ||
        this.teamDetails.sts == 'Match Over'
      ) {
        this.isMatcheStarted = true;

        this.isFirstinning = false;

        this.battingTeam1 = this.fullScoreBorard.bat1;
        this.bowlingTeam1 = this.fullScoreBorard.bow2;
        this.fallofwkt1 = this.fullScoreBorard.fow1;

        this.battingTeam2 = this.fullScoreBorard.bat2;
        this.bowlingTeam2 = this.fullScoreBorard.bow1;
        this.fallofwkt2 = this.fullScoreBorard.fow2;
      }
    });
  }

  getScore(eventID: any) {
    this.scoreApi.getScore(eventID).subscribe((result: any) => {
      let listmatches = result.result;
      this.teamName = 'Batting Team: ' + listmatches.cm.scot + ': ';
      this.teamScore = listmatches.cm.scor;
      this.teamOver = ' ' + listmatches.cm.scov + ' ov';
      this.teamStatusCode = listmatches.cm.sts;
      this.CommentryDisplaySatus = listmatches.cm.dis;
      this.teamshortName1 = listmatches.cm.t1sn;
      this.teamshortName2 = listmatches.cm.t2sn;
      this.teamName1 = listmatches.cm.t1n;
      this.teamName2 = listmatches.cm.t2n;
      this.matchTitle = listmatches.cm.til;

      localStorage.setItem('displayStatus', listmatches.cm.dis);
      localStorage.setItem('status', listmatches.cm.sts);

      //this.setTimerbydate();
    });
  }

  getSquadsPlayers(eventID: any) {
    this.scoreApi.getSquadsPlayers(eventID).subscribe((result: any) => {
      let listmatches = result;
      this.team1Namesquad = listmatches.tm.t1n;
      this.team2Namesquad = listmatches.tm.t2n;

      this.team1Squad = listmatches.t1p;
      this.team2Squad = listmatches.t2p;
    });
  }

  setCommentryURL(eid: any, tno: number) {
    this.scoreurl =
      'https://scoredesign.onlyscore.live/scorebord?id=' +
      eid +
      '&color=e51c28&font=f8d33d';
  }

  onChange(value: any) {
    let eventID = localStorage.getItem('EventID');
    this.setCommentryURL(eventID, parseInt(value.value));
  }

  getSocketConnect(vUri: any) {
    const connection = new signalR.HubConnectionBuilder().withUrl(vUri).build();

    connection.on('Score', (data) => {
      this.getCommnetryData(data);
    });

    connection
      .start()
      .then(() =>
        connection.invoke('ConnectScore', localStorage.getItem('EventID'))
      );
  }

  getCommnetryData(data: any) {
    this.teamName = 'Batting Team: ' + data.cm.scot + ': ';
    this.teamScore = data.cm.scor;
    this.teamOver = ' ' + data.cm.scov + ' ov';
    this.getFullScore(localStorage.getItem('EventID'));
  }

  setTimerbydate() {
    const status = this.teamStatusCode;
    this.teamStatusCode = status !== null ? status : '0';

    if (parseInt(this.teamStatusCode) == 1) {
      this.CommentryDisplaySatus = localStorage.getItem('displayStatus');

      this.CommentryDisplaySatus = this.CommentryDisplaySatus.replace(
        'Match Will Start on ',
        ''
      );
      this.CommentryDisplaySatus = this.CommentryDisplaySatus.replace(
        '  ',
        ' '
      );
      this.CommentryDisplaySatus = this.CommentryDisplaySatus.replace(
        'AM',
        ' AM'
      );
      this.CommentryDisplaySatus = this.CommentryDisplaySatus.replace(
        'PM',
        ' PM'
      );
      let getdate = this.CommentryDisplaySatus;

      this.countDownDate = new Date(getdate).getTime();
      // Update the count down every 1 second
      var x = setInterval(() => {
        if (this.countDownDate != NaN || this.countDownDate != undefined) {
          // Get today's date and time
          var now = new Date().getTime();
          // Find the distance between now and the count down date
          var distance = this.countDownDate - now;
          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Output the result in an element with id="demo"

          this.timer =
            days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';

          // If the count down is over, write some text
          if (distance < 0) {
            clearInterval(x);
            this.timer = 'Match Will start Soon';
          }
        } else {
          this.timer = this.CommentryDisplaySatus;
        }
      }, 1000);
    }
  }
}
