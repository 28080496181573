import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient) { }
  loadAppConfig() {
    let promise = new Promise<void>((resolve,reject) => {
      this.http.get('assets/json/config.json').subscribe((data: any) => {
        initData = data;
        resolve(data);
      });
    });
    return promise;
  }
}
export let initData: any = {};