import { Component, OnInit ,Renderer2 } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-cric-news',
  templateUrl: './cric-news.component.html',
  styleUrls: ['./cric-news.component.css']
})


export class CricNewsComponent implements OnInit {

 isNews :boolean = false;
 news_Articles:any;
 newsArr:any;
 nextUrl:any;
 getHeight:any;


 scrollEvent:any = (event: any): void => {
  const n = event.srcElement.scrollingElement.scrollTop;
  var elmnt = document.getElementById("newsDiv");
  if(elmnt){
    this.getHeight = elmnt.offsetHeight
  }
  
  if(n > this.getHeight){
    //  console.log('h '+this.getHeight);
    this.getNextPageNews(this.nextUrl);
  }
}

  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router,private httpclient:HttpClient) { 
    this.news_Articles = [];
    this.newsArr =[];
    this.nextUrl="";
    this.isNews = false;
  }

  topFunction() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngOnInit(): void {
    localStorage.setItem('news','');
    localStorage.setItem('nexturl',"");
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    this.getSportsNews();
    this.topFunction();
    window.addEventListener('scroll', this.scrollEvent, true);
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }
 

  getSportsNews(){
    try {
      this.scoreApi.getSportsNews().subscribe((result:any)=>{
        if(result.isSuccess){
          this.isNews = true;
          this.news_Articles = result.nl;
          this.nextUrl = result.nextUrl;
          for (let index = 0; index <  this.news_Articles.length; index++) {
             this.news_Articles[index].til = this.news_Articles[index].til.replace("&nbsp;&#8226;&nbsp;",",");
          }
          localStorage.setItem('news',JSON.stringify(this.news_Articles))
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async getNextPageNews(url:any){
    try {
      window.removeEventListener('scroll', this.scrollEvent, true);
      await this.scoreApi.getNextPageNews(url).subscribe((result:any)=>{
        if(result.isSuccess){        
          this.newsArr = result.nl;
  
          var localNews = localStorage.getItem('news');
          if(localNews)
          this.news_Articles = JSON.parse(localNews);
          
          this.nextUrl = result.nextUrl;
          localStorage.setItem('nexturl',this.nextUrl);
          for (let index = 0; index <  this.newsArr.length; index++) {
            this.newsArr[index].til = this.newsArr[index].til.replace("&nbsp;&#8226;&nbsp;","-");  
            this.news_Articles.push({
              cont: this.newsArr[index].cont, 
              dateTime: this.newsArr[index].dateTime,
              img: this.newsArr[index].img,
              mainUrl: this.newsArr[index].mainUrl,
              scon: this.newsArr[index].scon,
              til: this.newsArr[index].til
             });
          }
          localStorage.setItem('news',JSON.stringify(this.news_Articles));
          window.addEventListener('scroll', this.scrollEvent, true);
        }
      });
    } catch (error) {
      console.log(error);
    }

  }

  gotoDetails(url:any){
    localStorage.setItem('newsArticle',url);
    this.router.navigateByUrl('/news-article');
    return true;
  }
}
