<!-- Breadcrumb Section Begin -->
<section class="breadcrumb-section set-bg-wallpaper" data-setbg="assets/img/breadcrumb-bg.jpg">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="bs-text">
                    <h2>News Article</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Breadcrumb Section End -->

<!-- Blog Section Begin -->
<section class="blog-section spad" *ngIf="isArticle">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 left-blog-pad">
                <div class="blog-items">
                    <div class="large-blog" [style.background-image]="'url('+img+')'">
                        <div class="bi-tag">Cricket</div>
                    </div>
                </div>
                <div class="bd-text">
                    <h5>
                        <i class="fa fa-chevron-right"></i> {{til}} &nbsp;&nbsp;&nbsp; <i class="fa fa-calendar"></i><span class="darkColor">&nbsp;{{dateTime}}</span>
                    </h5>
                    <br>
                    <hr>
                    <br>
                    <div class="bd-more-title">
                        <p>
                            <i class="fa fa-edit"></i> {{headline}}
                        </p>
                        <p>
                            <i class="fa fa-edit"></i> {{scon}}
                        </p>
                        <p>
                            <i class="fa fa-edit"></i> {{cont}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <app-series-list></app-series-list>
            </div>
        </div>
    </div>
</section>
<!-- Blog Section End -->

<section class="blog-section spad" *ngIf="!isArticle">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 left-blog-pad">
                <div class="bd-text">
                    <h5><a [routerLink]="" class="darkColor"><i class="fa fa-edit"></i> Article Not Found</a></h5>
                    <br>
                    <hr>
                    <br>
                </div>
                <div class="more-blog">
                    <a [routerLink]="['/home']">Goto Home <i class="fa fa-arrow-left" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <app-series-list></app-series-list>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>