<!-- <div class="schedule-sidebar">
    <div class="ss-widget">
        <div class="section-title sidebar-title">
            <h3>Series <span class="foottertitle">List</span></h3>
        </div>
        <ul *ngFor="let seriers of seriersList">
            <li><a href="javascript:void(0)" id="{{seriers.cid}}">{{seriers.cna}}</a></li>
        </ul>
    </div>
</div> -->

<div class="blog-sidebar">
    <div class="bs-popular-tag">
        <div class="section-title sidebar-title">
            <h3>Series <span class="foottertitle">List</span></h3>
        </div>
        <div class="tags">
            <div *ngFor="let seriers of seriersList">
                <a href="javascript:void(0)" id="{{seriers.cid}}">{{seriers.cna}}</a>
            </div>
        </div>
    </div>
</div>