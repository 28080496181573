import { Component, OnInit ,Renderer2} from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';


declare var $: any;
@Component({
  selector: 'app-schedule-matches',
  templateUrl: './schedule-matches.component.html',
  styleUrls: ['./schedule-matches.component.css']
})
export class ScheduleMatchesComponent implements OnInit {
  scheduleMatchesList: any;
  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router) {
    this.scheduleMatchesList = [];
   }

  title = 'OnlyScore';
  
  topFunction() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  ngOnInit(): void {
    this. getScheduleMatchesList();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'assets/js/main.js';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);

    this.topFunction();
  }
  ngAfterViewInit() {
    // $('.owl-carousel').owlCarousel();
  }

  getScheduleMatchesList(){
    this.scoreApi.getScheduleMatchesList().subscribe((result:any)=>{
      let listmatches = result.cl;
      for (let index = 0; index < listmatches.length; index++) {
        const getTime =  listmatches[index].et;
        listmatches[index].et = this.tConvert(getTime);
        listmatches[index].et = listmatches[index].et.replace(':00','').replace('PM',' PM').replace('AM',' AM');
        // const element = listmatches[index];
        // const dateArr = element.ed.split("/")
        // const newDate = dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
        // listmatches[index].ed = newDate + " " +listmatches[index].et;
    }
      this.scheduleMatchesList= listmatches;
    });
  }

  tConvert (time:any) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
  
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }

  gotoCommentry(eventID:any,date:any,time:any){
    localStorage.setItem('EventID',eventID);
    localStorage.setItem('Matchdatetime',date);
    localStorage.setItem('Matchtime',time);
    this.router.navigateByUrl('/commentry');
    return true;
  }
}
