<div class="section-title" *ngIf="isNews">
    <h3>Latest <span class="foottertitle">News</span></h3>
</div>
<div class="swiper mySwiper">
    <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let newsr of news_Articles">
            <div class="news-item left-news nav-news" (click)="gotoDetails(newsr.mainUrl)">
                <div class="ni-pic set-bg" [attr.data-setbg]="newsr.img" [style.background-image]="'url('+newsr.img+')'" (click)="gotoDetails(newsr.mainUrl)">
                    <div class="ni-tag">Cricket</div>
                </div>
                <div class="ni-text" [attr.alt]='newsr.til'>
                    <h5><a [routerLink]="" (click)="gotoDetails(newsr.mainUrl)" class="title_news">{{newsr.til}}</a></h5>
                    <p class="">{{newsr.cont}}</p>
                    <p style="color: black;"> <i class="fa fa-clock-o" aria-hidden="true"></i> {{newsr.dateTime}}</p>
                </div>
            </div>
        </div>
        <div class="swiper-slide"></div>
    </div>
    <div class="swiper-pagination"></div>
</div>

<!-- <div *ngFor="let newsr of news_Articles">
    <div class="news-item left-news" (click)="gotoDetails(newsr.mainUrl)">
        <div class="ni-pic set-bg" [attr.data-setbg]="newsr.img" [style.background-image]="'url('+newsr.img+')'" (click)="gotoDetails(newsr.mainUrl)">
            <div class="ni-tag">Cricket</div>
        </div>
        <div class="ni-text" [attr.alt]='newsr.til'>
            <h5><a [routerLink]="" (click)="gotoDetails(newsr.mainUrl)" class="title_news">{{newsr.til}}</a>,
            </h5>
            <p class="">{{newsr.cont}}</p>
            <p style="color: black;">{{newsr.dateTime}}</p>
        </div>
    </div>
</div> -->