import { Component, OnInit,Renderer2 } from '@angular/core';
import { ScoreapiService } from '../services/scoreapi.service';
import { Router } from '@angular/router';
import { SafePipe } from '../Pipe/safe.pipe';

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.css']
})
export class LatestNewsComponent implements OnInit {

  news_Articles:any = [];
  isNews:boolean=false;
  constructor(private renderer: Renderer2,private scoreApi : ScoreapiService, private router:Router) { 
    // this.news_Articles=[];
  }

  ngOnInit(): void {
    this.getSportsNews();
  }

  getSportsNews(){
    try {
      this.scoreApi.getSportsNews().subscribe((result:any)=>{
        if(result.isSuccess){
          this.isNews = true;
          this.news_Articles = result.nl;
          for (let index = 0; index <  this.news_Articles.length; index++) {
             this.news_Articles[index].til = this.news_Articles[index].til.replace("&nbsp;&#8226;&nbsp;","-");
          }
          return this.news_Articles;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  gotoDetails(url:any){
    localStorage.setItem('newsArticle',url);
    this.router.navigateByUrl('/news-article');
    return true;
  }
}
