import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { LiveMatchesComponent } from './live-matches/live-matches.component';
import { ScheduleMatchesComponent } from './schedule-matches/schedule-matches.component';
import { CompletedMatchesComponent } from './completed-matches/completed-matches.component';
import { HttpClientModule } from '@angular/common/http';
import { CommentryComponent } from './commentry/commentry.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SafePipe } from './Pipe/safe.pipe';
import { SeriesListComponent } from './series-list/series-list.component';
import { LatestNewsComponent } from './latest-news/latest-news.component';
import { CricNewsComponent } from './cric-news/cric-news.component';
import { NewsArticleComponent } from './news-article/news-article.component';
import { DateFormatePipe } from './Pipe/datePipe';
import { DatePipe } from '@angular/common';
import { ConfigService } from './services/config.service';
export function loadConfigurations(configService: ConfigService) {
  return () => configService.loadAppConfig();
}
const routerArr = [
  {
    path: 'completedmatches',
    component: CompletedMatchesComponent,
  },
  {
    path: 'schedulematches',
    component: ScheduleMatchesComponent,
  },
  {
    path: 'livematches',
    component: LiveMatchesComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'commentry',
    component: CommentryComponent,
  },
  {
    path: 'cric-news',
    component: CricNewsComponent,
  },
  {
    path: 'news-article',
    component: NewsArticleComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LiveMatchesComponent,
    ScheduleMatchesComponent,
    CompletedMatchesComponent,
    CommentryComponent,
    SafePipe,
    DateFormatePipe,
    SeriesListComponent,
    LatestNewsComponent,
    CricNewsComponent,
    NewsArticleComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routerArr, { useHash: false }),
    HttpClientModule,
    OwlModule,
  ],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigService], //,CryptoService
      useFactory: loadConfigurations,
    },
  ],
  exports: [SafePipe, DateFormatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
