<!--Whole Container -->
<div class="rca-container rca-margin " *ngIf="isMatcheStarted ">
    <!--Live ScoreBoard -->

    <div class="">
        <!--Widget Inner -->
        <div class="" *ngIf="isFirstinning">
            <!--Match Series-->
            <div class="rca-medium-widget">
                <div id="divIframe" class="iframeboxshadow">
                    <iframe id="idIframe" class="iframeclass " [src]="scoreurl | safe: 'resourceUrl'" frameborder="0 " width="100% " scrolling="no" style="border: none;border-radius: 40px 40px 0px 0px;margin-top: 5px;"></iframe>
                </div>
                <div id="teamdetails ">
                    <ul class="rca-tab-list ">
                        <li class="rca-tab-link scoreCard " data-tab="tab-41" [class.active]="setTag=='tab1'" (click)="setTag='tab1'">{{teamname}}</li>
                        <li class="rca-tab-link scoreCard " data-tab="squad" id="squad" [class.active]="setTag=='squad'" (click)="setTag='squad'">SQUAD</li>
                        <li class="d-none">
                            <select (change)="onChange($event.target) ">
                                <option value="0 " selected disabled>--Select Theme--</option>
                                <option  *ngFor="let item of list " value="{{item.id}} ">{{item.name}}</option>
                            </select>
                        </li>
                    </ul>
                    <div id="tab-41" class="rca-tab-content rca-padding" [class.active]="setTag=='tab1'" *ngIf="setTag=='tab1'">
                        <div class="rca-batting-score rca-padding ">
                            <div class="rca-row ">
                                <div class="rca-header rca-table ">
                                    <div class="rca-col rca-player table-headertex">
                                        Batter
                                    </div>
                                    <div class="rca-col table-headertex">
                                        R
                                    </div>
                                    <div class="rca-col table-headertex">
                                        4s
                                    </div>
                                    <div class="rca-col table-headertex">
                                        6s
                                    </div>
                                    <div class="rca-col table-headertex">
                                        SR
                                    </div>
                                </div>
                            </div>
                            <div class="rca-row bottomBorder" *ngFor="let bat1 of battingTeam1 ">
                                <div class="rca-table ">
                                    <div class="rca-col rca-player">
                                        {{bat1.btn}}
                                        <br/>
                                        <span [class.foottertitle]="bat1.ot=='NOT OUT'" style="font-size: 12px;">{{bat1.wkp}}</span>
                                    </div>
                                    <div class=" rca-col ">
                                        {{bat1.rbl}}
                                    </div>
                                    <div class="rca-col ">
                                        {{bat1.four}}
                                    </div>
                                    <div class="rca-col ">
                                        {{bat1.six}}
                                    </div>
                                    <div class="rca-col ">
                                        {{bat1.strk}}
                                    </div>
                                </div>
                            </div>
                            <div class="rca-clear "></div>
                            <div class="rca-padding ">
                            </div>
                        </div>
                        <div class="rca-bowling-score rca-padding ">
                            <div class="rca-row ">
                                <div class="rca-header rca-table ">
                                    <div class="rca-col rca-player table-headertex">
                                        Bowler
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        O
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        M
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        R
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        W
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        Dots
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        Extras
                                    </div>
                                </div>
                            </div>
                            <div class="rca-row bottomBorder" *ngFor="let bowl1 of bowlingTeam1 ">
                                <div class="rca-table ">
                                    <div class="rca-col rca-player ">
                                        {{bowl1.pln}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.ovr}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.mov}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.trun}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.wkt}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.dot}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.xtr}}
                                    </div>
                                </div>
                            </div>
                            <div class="rca-clear "></div>
                            <div class="rca-row rca-padding ">
                                <h3>Fall of Wicket:</h3>
                                <ul class="rca-wicket-falls " *ngFor="let fwkt1 of fallofwkt1 ">
                                    <li><span>{{fwkt1.pn1}}</span> at <span>{{fwkt1.sco1}}</span>, in <span>{{fwkt1.ovr1}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="squad" class="rca-tab-content rca-padding" [class.active]="setTag=='squad'" *ngIf="setTag=='squad'">
                        <ul class="rca-tab-list small">
                            <li class="rca-tab-link" data-tab="tab-411" [class.active]="setSubTag=='tab-411'" (click)="setSubTag='tab-411'">{{team1Namesquad}}</li>
                            <li class="rca-tab-link" data-tab="tab-412" [class.active]="setSubTag=='tab-412'" (click)="setSubTag='tab-412'">{{team2Namesquad}}</li>
                        </ul>
                        <div id="tab-411" class="rca-tab-content rca-padding" [class.active]="setSubTag=='tab-411'" *ngIf="setSubTag=='tab-411'">
                            <div class="rca-batting-score">
                                <div *ngFor="let t1sq of team1Squad">
                                    <div class="rca-player-detail listPlayer">
                                        <img src="assets/img/keeper.svg" style="width:30px;height:auto;" *ngIf="t1sq.isKep=='true' || t1sq.pty == 'WicketKeeper'">
                                        <img src="assets/img/ball.png" style="width:30px;height:auto;" *ngIf="t1sq.pty == 'Bowler'">
                                        <img src="assets/img/bat.png" style="width:30px;height:auto;" *ngIf="t1sq.pty == 'BatsMan'">
                                        <img src="assets/img/allRounder.svg" style="width:30px;height:auto;" *ngIf="t1sq.pty == 'AllRounder'"> &nbsp;
                                        <span class="rca-player">{{t1sq.pn}}</span>
                                        <span class="rca-highlight-text" *ngIf="t1sq.isKep=='true' || t1sq.pty == 'WicketKeeper'">(WK)</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t1sq.pty == 'BatsMan'">Batter</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t1sq.pty != 'BatsMan'">{{t1sq.pty}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-412" class="rca-tab-content rca-padding" [class.active]="setSubTag=='tab-412'" *ngIf="setSubTag=='tab-412'">
                            <div class="rca-batting-score">
                                <div *ngFor="let t2sq of team2Squad">
                                    <div class="rca-player-detail listPlayer">
                                        <img src="assets/img/keeper.svg" style="width:30px;height:auto;" *ngIf="t2sq.isKep=='true' || t2sq.pty == 'WicketKeeper'">
                                        <img src="assets/img/ball.png" style="width:30px;height:auto;" *ngIf="t2sq.pty == 'Bowler'">
                                        <img src="assets/img/bat.png" style="width:30px;height:auto;" *ngIf="t2sq.pty == 'BatsMan'">
                                        <img src="assets/img/allRounder.svg" style="width:30px;height:auto;" *ngIf="t2sq.pty == 'AllRounder'"> &nbsp;
                                        <span class="rca-player">{{t2sq.pn}}</span>
                                        <span class="rca-highlight-text" *ngIf="t2sq.isKep=='true' || t2sq.pty == 'WicketKeeper'">(WK)</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t2sq.pty == 'BatsMan'">Batter</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t2sq.pty != 'BatsMan'">{{t2sq.pty}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="third " *ngIf="!isFirstinning ">
            <!--Match Series-->
            <div class="rca-medium-widget">
                <div id="divIframe" class="iframeboxshadow">
                    <iframe class="iframeclass" id="idIframe" [src]="scoreurl | safe: 'resourceUrl' " frameborder="0 " width="100% " scrolling="no " style="border: none;border-radius: 40px 40px 0px 0px;"></iframe>
                </div>
                <div id="teamdetails">
                    <ul class="rca-tab-list " id="teamdetails ">
                        <li class="rca-tab-link scoreCard " data-tab="tab-41" id="tab41" [class.active]="setTag=='tab1'" (click)="setTag='tab1'">{{teamDetails.tn1}}</li>
                        <li class="rca-tab-link scoreCard " data-tab="tab-43" id="tab43" [class.active]="setTag=='tab2'" (click)="setTag='tab2'">{{teamDetails.tn2}}</li>
                        <li class="rca-tab-link scoreCard " data-tab="squad" id="squad" [class.active]="setTag=='squad'" (click)="setTag='squad'">SQUAD</li>
                        <li class="d-none">
                            <select (change)="onChange($event.target) ">
                                <option value="0 " selected disabled>--Select Theme--</option>
                                <option  *ngFor="let item of list " value="{{item.id}} ">{{item.name}}</option>
                            </select>
                        </li>
                    </ul>
                    <div id="tab-41 " class="rca-tab-content rca-padding" [class.active]="setTag=='tab1' " *ngIf="setTag=='tab1'">
                        <div class="rca-batting-score rca-padding ">
                            <div class="rca-row ">
                                <div class="rca-header rca-table ">
                                    <div class="rca-col rca-player table-headertex">
                                        Batter
                                    </div>
                                    <div class="rca-col table-headertex">
                                        R
                                    </div>
                                    <div class="rca-col table-headertex">
                                        4s
                                    </div>
                                    <div class="rca-col table-headertex">
                                        6s
                                    </div>
                                    <div class="rca-col table-headertex">
                                        SR
                                    </div>
                                </div>
                            </div>
                            <div class="rca-row bottomBorder" *ngFor="let bat1 of battingTeam1 ">
                                <div class="rca-table ">
                                    <div class="rca-col rca-player">
                                        {{bat1.btn}}
                                        <br/>
                                        <span [class.foottertitle]="bat1.ot=='NOT OUT'" style="font-size: 12px;">{{bat1.wkp}}</span>
                                    </div>
                                    <div class="rca-col">
                                        {{bat1.rbl}}
                                    </div>
                                    <div class="rca-col">
                                        {{bat1.four}}
                                    </div>
                                    <div class="rca-col">
                                        {{bat1.six}}
                                    </div>
                                    <div class="rca-col">
                                        {{bat1.strk}}
                                    </div>
                                </div>
                            </div>
                            <div class="rca-clear "></div>
                            <div class="rca-padding ">
                            </div>
                        </div>
                        <div class="rca-bowling-score rca-padding ">
                            <div class="rca-row ">
                                <div class="rca-header rca-table ">
                                    <div class="rca-col rca-player table-headertex">
                                        Bowler
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        O
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        M
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        R
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        W
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        Econ
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        Extras
                                    </div>
                                </div>
                            </div>
                            <div class="rca-row bottomBorder" *ngFor="let bowl1 of bowlingTeam1 ">
                                <div class="rca-table ">
                                    <div class="rca-col rca-player ">
                                        {{bowl1.pln}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.ovr}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.mov}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.trun}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.wkt}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.dot}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl1.xtr}}
                                    </div>
                                </div>
                            </div>
                            <div class="rca-clear "></div>
                            <div class="rca-row rca-padding ">
                                <h3>Fall of Wicket:</h3>
                                <ul class="rca-wicket-falls " *ngFor="let fwkt1 of fallofwkt1 ">
                                    <li><span>{{fwkt1.pn1}}</span> at <span>{{fwkt1.sco1}}</span>, in <span>{{fwkt1.ovr1}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="tab-43 " class="rca-tab-content rca-padding" [class.active]="setTag=='tab2'" *ngIf="setTag=='tab2'">
                        <div class="rca-batting-score rca-padding ">
                            <div class="rca-row ">
                                <div class="rca-header rca-table ">
                                    <div class="rca-col rca-player table-headertex">
                                        Batter
                                    </div>
                                    <div class="rca-col table-headertex">
                                        R
                                    </div>
                                    <div class="rca-col table-headertex">
                                        4s
                                    </div>
                                    <div class="rca-col table-headertex">
                                        6s
                                    </div>
                                    <div class="rca-col table-headertex">
                                        SR
                                    </div>
                                </div>
                            </div>
                            <div class="rca-row " *ngFor="let bat2 of battingTeam2">
                                <div class="rca-table bottomBorder">
                                    <div class="rca-col rca-player ">
                                        {{bat2.btn}}
                                        <br/>
                                        <span [class.foottertitle]="bat2.ot=='NOT OUT'" style="font-size: 12px;">{{bat2.wkp}}</span>
                                    </div>
                                    <div class="rca-col ">
                                        {{bat2.rbl}}
                                    </div>
                                    <div class="rca-col ">
                                        {{bat2.four}}
                                    </div>
                                    <div class="rca-col ">
                                        {{bat2.six}}
                                    </div>
                                    <div class="rca-col ">
                                        {{bat2.strk}}
                                    </div>
                                </div>
                            </div>
                            <div class="rca-clear "></div>
                            <div class="rca-padding ">
                            </div>
                        </div>
                        <div class="rca-bowling-score rca-padding ">
                            <div class="rca-row ">
                                <div class="rca-header rca-table ">
                                    <div class="rca-col rca-player table-headertex">
                                        Bowler
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        O
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        M
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        R
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        W
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        Econ
                                    </div>
                                    <div class="rca-col small table-headertex">
                                        Extras
                                    </div>
                                </div>
                            </div>
                            <div class="rca-row" *ngFor="let bowl2 of bowlingTeam2 ">
                                <div class="rca-table bottomBorder">
                                    <div class="rca-col rca-player ">
                                        {{bowl2.pln}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl2.ovr}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl2.mov}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl2.trun}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl2.wkt}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl2.dot}}
                                    </div>
                                    <div class="rca-col small ">
                                        {{bowl2.xtr}}
                                    </div>
                                </div>
                            </div>
                            <div class="rca-clear "></div>
                            <div class="rca-row rca-padding ">
                                <h3>Fall of Wicket:</h3>
                                <ul class="rca-wicket-falls " *ngFor="let fwkt2 of fallofwkt2 ">
                                    <li><span>{{fwkt2.pn2}}</span> at <span>{{fwkt2.sco2}}</span>, in <span>{{fwkt2.ovr2}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div id="squad" class="rca-tab-content rca-padding" [class.active]="setTag=='squad'" *ngIf="setTag=='squad'">
                        <ul class="rca-tab-list small">
                            <li class="rca-tab-link" data-tab="tab-411" [class.active]="setSubTag=='tab-411'" (click)="setSubTag='tab-411'">{{team1Namesquad}}</li>
                            <li class="rca-tab-link" data-tab="tab-412" [class.active]="setSubTag=='tab-412'" (click)="setSubTag='tab-412'">{{team2Namesquad}}</li>
                        </ul>
                        <div id="tab-411" class="rca-tab-content rca-padding" [class.active]="setSubTag=='tab-411'" *ngIf="setSubTag=='tab-411'">
                            <div class="rca-batting-score">
                                <div *ngFor="let t1sq of team1Squad">
                                    <div class="rca-player-detail listPlayer">
                                        <img src="assets/img/keeper.svg" style="width:30px;height:auto;" *ngIf="t1sq.isKep=='true' || t1sq.pty == 'WicketKeeper'">
                                        <img src="assets/img/ball.png" style="width:30px;height:auto;" *ngIf="t1sq.pty == 'Bowler'">
                                        <img src="assets/img/bat.png" style="width:30px;height:auto;" *ngIf="t1sq.pty == 'BatsMan'">
                                        <img src="assets/img/allRounder.svg" style="width:30px;height:auto;" *ngIf="t1sq.pty == 'AllRounder'"> &nbsp;
                                        <span class="rca-player">{{t1sq.pn}}</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t1sq.pty == 'BatsMan'">Batter</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t1sq.pty != 'BatsMan'">{{t1sq.pty}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="tab-412 " class="rca-tab-content rca-padding " [class.active]="setSubTag=='tab-412' " *ngIf="setSubTag=='tab-412' ">
                            <div class="rca-batting-score ">
                                <div *ngFor="let t2sq of team2Squad ">
                                    <div class="rca-player-detail listPlayer">
                                        <img src="assets/img/keeper.svg" style="width:30px;height:auto;" *ngIf="t2sq.isKep=='true' || t2sq.pty == 'WicketKeeper'">
                                        <img src="assets/img/ball.png" style="width:30px;height:auto;" *ngIf="t2sq.pty == 'Bowler'">
                                        <img src="assets/img/bat.png" style="width:30px;height:auto;" *ngIf="t2sq.pty == 'BatsMan'">
                                        <img src="assets/img/allRounder.svg" style="width:30px;height:auto;" *ngIf="t2sq.pty == 'AllRounder'"> &nbsp;
                                        <span class="rca-player ">{{t2sq.pn}}</span>
                                        <span class="rca-highlight-text " *ngIf="t2sq.isKep=='true' || t2sq.pty=='WicketKeeper' ">(WK)</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t2sq.pty == 'BatsMan'">Batter</span>
                                        <span class="rca-right rca-basic-text" *ngIf="t2sq.pty != 'BatsMan'">{{t2sq.pty}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="rca-container " *ngIf="!isMatcheStarted ">
    <div class=" ">
        <div class="row" class="iframeclass_">
            <iframe id="idIframe" class="iframeclass" [src]="scoreurl | safe: 'resourceUrl' " frameborder="0 " scrolling="no" width="100% " style="border: none; "></iframe>
        </div>
        <div class="row " class="openstates ">
            <div class="rca-medium-widget rca-padding rca-completed-match iframeboxshadow">
                <div class="rca-right rca-basic-text ">{{CommentryDisplaySatus}}</div>
                <div class="rca-clear "></div>
                <div class="rca-padding ">
                    <h3 class="rca-match-title rca-theme-text ">
                        <span>{{matchTitle}}</span>
                    </h3>
                    <div class="rca-top-padding ">
                        <div class="rca-teams rca-table ">
                            <div class="team rca-cell ">{{teamName1}}</div>
                            <div class="rca-vs rca-cell "></div>
                            <div class="team rca-cell ">{{teamName2}}</div>
                        </div>
                    </div>
                    <div class="rca-match-start ">
                        <h3>Match Info</h3>
                        <div class="rca-padding ">
                            <p class="rca-center ">
                                {{CommentryDisplaySatus}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>