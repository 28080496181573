<!-- Hero Section Begin -->
<section class="hero-section background-section cursorPointer" id="mainBanner">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="hs-item">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="hs-text" style="display:none;">
                                    <h4>Live Score & Cricket Stats</h4>
                                    <h2>With Fast, Reliable Cricket Score</h2>
                                    <a href="javascript:void(0)" class="primary-btn foottertitle d-none" id="matchlist">Matches List</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Hero Section End -->

<!-- Trending News Section Begin -->
<div class="trending-news-section" *ngIf="!isLive">
    <div class="container">
        <div class="tn-title">Matches &nbsp;<i class="fa fa-caret-right"></i></div>
        <owl-carousel [options]="newssliderOptins" [carouselClasses]="['owl-theme', 'row', 'sliding']">
            <div class="news-slider">
                <div class="nt-item">&nbsp;&nbsp;&nbsp;Currently There are No Live Matches</div>
            </div>
        </owl-carousel>
    </div>
</div>
<!-- Trending News Section End -->

<!--Live Matches-->
<section class="video-section" *ngIf="isLive">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                    <h3><span class="dot"></span>&nbsp;Live <span class="foottertitle">Matches</span></h3>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="sports-slider swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let liveMatch of liveMatchList">
                        <!-- <div class="ms-content">
                            <div class="mc-table" data-setbg="../assets/img/videos/video-1.jpg">
                                <table class="commentryDiv">
                                    <tbody class="commentryDiv">
                                        <tr class="commentryDiv">
                                            <td class="left-team commentryDiv">
                                                <img src={{liveMatch.te1i}} alt={{liveMatch.te1n}} style="width:90px;height:85px;">
                                                <h6>{{liveMatch.s1n}}</h6>
                                            </td>
                                            <td class="mt-content commentryDiv">
                                                <div class="mc-op foottertitle title_match" style="font-size: medium;"><a href="javascript:void(0)" class="foottertitle" title={{liveMatch.en}} (click)="gotoCommentry(liveMatch.eid)">{{liveMatch.en}}</a></div>
                                                <h4>VS</h4>
                                                <div class="mc-op">
                                                    <small style="font-size:small;">
                                                                <span>{{liveMatch.t1s}}</span> : <span>{{liveMatch.t2s}}</span>
                                                            </small>
                                                </div>
                                            </td>
                                            <td class="right-team commentryDiv">
                                                <img src={{liveMatch.te2i}} alt={{liveMatch.te2n}} style="width:90px;height:85px;">
                                                <h6>{{liveMatch.s2n}}</h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                        <div class="card mc-table">
                            <div class="card-body cursorPointer">
                                <div class="" (click)="gotoCommentry(liveMatch.eid)">
                                    <div class="mc-op foottertitle title_match" style="font-size: medium;"><a href="javascript:void(0)" class="foottertitle" (click)="gotoCommentry(liveMatch.eid)">{{liveMatch.en}}</a></div>
                                    <div class="row mt-content align-items-center">
                                        <div class="col-5 col-sm-5 left-team" (click)="gotoCommentry(liveMatch.eid)">
                                            <div style="max-width: 160px;max-height: 80px;">
                                            <img src={{liveMatch.te1i}} alt={{liveMatch.te1n}} class="homeimg">
                                        </div>
                                            <h6 style="color: #c5141f;" class="title_match">{{liveMatch.te1n}}</h6>
                                            <div class="mc-op" style="color: #c5141f;"><b><small style="font-size:small;"><span>{{liveMatch.t1s}}</span></small></b></div>
                                        </div>
                                        <div class="col-2 col-sm-2 mt-content d-flex align-items-center" (click)="gotoCommentry(liveMatch.eid)">
                                            <div class="vs-image-content"><svg _ngcontent-ofp-c75="" width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M0.613599 41.3664C0.274718 41.0164 0.274718 40.449 0.613599 40.0991L6.26718 34.2608C6.60606 33.9109 7.1555 33.9109 7.49438 34.2608C7.83326 34.6108 7.83326 35.1782 7.49438 35.5281L1.8408 41.3664C1.50192 41.7163 0.95248 41.7163 0.613599 41.3664Z" fill="#897800"></path><path _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M35.348 7.73919C35.0091 7.38924 35.0091 6.82185 35.348 6.4719L41.0016 0.633645C41.3404 0.283695 41.8899 0.283694 42.2288 0.633645C42.5676 0.983596 42.5676 1.55098 42.2288 1.90093L36.5752 7.73919C36.2363 8.08914 35.6869 8.08914 35.348 7.73919Z" fill="#897800"></path><path _ngcontent-ofp-c75="" d="M37.5518 19.5002C37.5518 28.82 30.4388 36.3751 21.6646 36.3751C12.8903 36.3751 5.77734 28.82 5.77734 19.5002C5.77734 10.1805 12.8903 2.62529 21.6646 2.62529C30.4388 2.62529 37.5518 10.1805 37.5518 19.5002Z" fill="#c5141f"></path><path _ngcontent-ofp-c75="" d="M20.4233 14.0316L16.5233 22.469L12.6564 14.0316H10.3164L15.3947 24.969H17.519L22.5808 14.0316H20.4233Z" fill="#fbfbfb"></path><path _ngcontent-ofp-c75="" d="M27.4079 25.1253C30.5944 25.1253 32.1046 23.6253 32.1046 21.8753C32.1046 17.8597 25.2173 19.3753 25.2173 17.0472C25.2173 16.219 25.9475 15.5628 27.7399 15.5628C28.7522 15.5628 29.8973 15.844 30.9429 16.4222L31.6233 14.8441C30.611 14.2191 29.1505 13.8753 27.7565 13.8753C24.5701 13.8753 23.0764 15.3753 23.0764 17.1409C23.0764 21.2034 29.9803 19.6565 29.9803 22.0159C29.9803 22.8284 29.2169 23.4378 27.4079 23.4378C25.9807 23.4378 24.5037 22.9378 23.5743 22.2346L22.8275 23.8128C23.79 24.594 25.599 25.1253 27.4079 25.1253Z" fill="#fbfbfb"></path></svg></div>
                                        </div>
                                        <div class="col-5 col-sm-5 right-team" (click)="gotoCommentry(liveMatch.eid)">
                                            <div style="max-width: 160px;max-height: 80px;">
                                            <img src={{liveMatch.te2i}} alt={{liveMatch.te2n}} class="homeimg">
                                            </div>
                                            <h6 style="color: #c5141f;" class="title_match">{{liveMatch.te2n}}</h6>
                                            <div class="mc-op" style="color: #c5141f;"><b><small style="font-size:small;"><span>{{liveMatch.t2s}}</span></small></b></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-nav-wrapper">
                    <div class="swiper-button-next swiper-button-white"></div>
                    <div class="swiper-button-prev swiper-button-white"></div>
                </div>
            </div>


        </div>
    </div>
</section>

<!-- Upcoming Section Begin -->
<section class="match-section set-bg" id="HomeupcommingMatches">
    <div class="container">
        <div class="row">
            <div class="">
                <h4 class="foottertitle" style="margin-left: 27px;">Upcoming Matches</h4>
                <br />
                <div id="upcoming list" class="list">
                    <div class="ms-content upcomingtblp10" *ngFor="let Match of MatchList">
                        <div class="mc-table commentryDiv" (click)="gotoCommentry(Match.eid)" title={{Match.en}}>
                            <div class="card mc-table upcomingCard">
                                <div class="card-body cursorPointer">
                                    <div class="" (click)="gotoCommentry(Match.eid)">
                                        <div class="mc-op foottertitle" style="font-size: medium;"><a href="javascript:void(0)" class="commentryDatetime dateTime" (click)="gotoCommentry(Match.eid)">{{Match.ed | dateFormate}}</a> <span class="spandate">{{Match.et}}</span></div>
                                        <div class="row mt-content align-items-center">
                                            <div class="col-5 col-sm-5 left-team" (click)="gotoCommentry(Match.eid)">
                                                <div style="max-width: 160px;max-height: 80px;">
                                                <img src={{Match.te1i}} alt={{Match.te1n}} class="homeimg">
                                            </div>
                                                <h6 style="color: #c5141f;" class="title_match">{{Match.te1n}}</h6>
                                            </div>
                                            <div class="col-2 col-sm-2 mt-content d-flex align-items-center" (click)="gotoCommentry(Match.eid)">
                                                <div class="vs-image-content"><svg _ngcontent-ofp-c75="" width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M0.613599 41.3664C0.274718 41.0164 0.274718 40.449 0.613599 40.0991L6.26718 34.2608C6.60606 33.9109 7.1555 33.9109 7.49438 34.2608C7.83326 34.6108 7.83326 35.1782 7.49438 35.5281L1.8408 41.3664C1.50192 41.7163 0.95248 41.7163 0.613599 41.3664Z" fill="#897800"></path><path _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M35.348 7.73919C35.0091 7.38924 35.0091 6.82185 35.348 6.4719L41.0016 0.633645C41.3404 0.283695 41.8899 0.283694 42.2288 0.633645C42.5676 0.983596 42.5676 1.55098 42.2288 1.90093L36.5752 7.73919C36.2363 8.08914 35.6869 8.08914 35.348 7.73919Z" fill="#897800"></path><path _ngcontent-ofp-c75="" d="M37.5518 19.5002C37.5518 28.82 30.4388 36.3751 21.6646 36.3751C12.8903 36.3751 5.77734 28.82 5.77734 19.5002C5.77734 10.1805 12.8903 2.62529 21.6646 2.62529C30.4388 2.62529 37.5518 10.1805 37.5518 19.5002Z" fill="#c5141f"></path><path _ngcontent-ofp-c75="" d="M20.4233 14.0316L16.5233 22.469L12.6564 14.0316H10.3164L15.3947 24.969H17.519L22.5808 14.0316H20.4233Z" fill="#fbfbfb"></path><path _ngcontent-ofp-c75="" d="M27.4079 25.1253C30.5944 25.1253 32.1046 23.6253 32.1046 21.8753C32.1046 17.8597 25.2173 19.3753 25.2173 17.0472C25.2173 16.219 25.9475 15.5628 27.7399 15.5628C28.7522 15.5628 29.8973 15.844 30.9429 16.4222L31.6233 14.8441C30.611 14.2191 29.1505 13.8753 27.7565 13.8753C24.5701 13.8753 23.0764 15.3753 23.0764 17.1409C23.0764 21.2034 29.9803 19.6565 29.9803 22.0159C29.9803 22.8284 29.2169 23.4378 27.4079 23.4378C25.9807 23.4378 24.5037 22.9378 23.5743 22.2346L22.8275 23.8128C23.79 24.594 25.599 25.1253 27.4079 25.1253Z" fill="#fbfbfb"></path></svg></div>
                                                <br>
                                            </div>
                                            <div class="col-5 col-sm-5 right-team" (click)="gotoCommentry(Match.eid)">
                                                <div style="max-width: 160px;max-height: 80px;">
                                                <img src={{Match.te2i}} alt={{Match.te2n}} class="homeimg">
                                            </div>
                                                <h6 style="color: #c5141f;" class="title_match">{{Match.te2n}}</h6>
                                            </div>
                                        </div>
                                        <div class="mc-op foottertitle" style="font-size: medium;"><a href="javascript:void(0)" class="foottertitle" (click)="gotoCommentry(Match.eid)">{{Match.mtyp}}</a>,<i class="fa fa-map-marker match_location" aria-hidden="true"> <span>{{Match.loc}}</span></i></div>
                                        <!-- <div class="mc-op">
                                            <span class="" style="font-size:small; color: #fff;">{{Match.ed | date :'EEE, MMM d y'}}</span> &nbsp;&nbsp;
                                        </div>
                                        <div class="mc-op">
                                            <div>
                                                <span class="foottertitle">{{Match.ed | date :'hh:mm a'}}</span>
                                            </div>
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                            <!-- <table class="commentryDiv home_tables">
                                <tbody class="commentryDiv">
                                    <tr class="commentryDiv">
                                        <td class="left-team commentryDiv">
                                            <img src={{Match.te1i}} alt={{Match.te1n}} style="width:100px;height:100px;">
                                            <h6 class="upcoimg_team setunDerscoreColor">{{Match.te1n}}</h6>
                                        </td>
                                        <td class="mt-content commentryDiv">
                                            <div class="mc-op foottertitle title_match_upcoming" title={{Match.en}} style="font-size: medium;">{{Match.en}}</div>
                                            <h4><span class="foottertitle" style="font-size: small;">{{Match.mtyp}}</span> </h4>
                                            <div class="mc-op"><span class="setDate" style="font-size:small;" id="setDate_{{Match.eid}}">{{Match.ed | date :'EEE, MMM d, y, hh:mm a'}}</span></div>
                                        </td>
                                        <td class="right-team commentryDiv">
                                            <img src={{Match.te2i}} alt={Match.te2n}} style="width:100px;height:100px;">
                                            <h6 class="upcoimg_team">{{Match.te2n}}</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<br>
<br/>
<!-- Latest News Section Begin -->
<section class="latest-section" *ngIf="isNews">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="section-title latest-title">
                    <h3>Latest <span class="foottertitle">News</span></h3>
                    <ul>
                        <li>Cricket News </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="blog-items">
                            <div class="single-item boxshadowlight" *ngFor="let newsr of news_Articles">
                                <div class="bi-pic nav">
                                    <img class="mobileNewimage" src="{{newsr.img}}" alt="{{newsr.til}}">
                                </div>
                                <div class="">
                                    <h5 class="bfcolorGrey paddingtop4"><p class="foottertitle ptag">{{newsr.til}}</p></h5>
                                    <p class="ptag">{{newsr.cont}} </p>
                                    <p class="ptag">{{newsr.scon}} <small> <p class="cursorPointer news_click" style="color: blue;" (click)="gotoDetails(newsr.mainUrl)">Read more</p></small> </p>
                                    <!-- <p> <i class="fa fa-clock-o"></i> {{newsr.dateTime}} : by cricbuzz</p> -->
                                    <ul>
                                        <li style="color:#333" class="mobi-newsDatetime"> <i class="fa fa-clock-o"></i> {{newsr.dateTime}} : by cricbuzz</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="news-item boxshadowlight">
                            <div class="ni-pic">
                                <img class="mobileNewimage" src="{{newsr.img}}" alt="{{newsr.til}}">
                            </div>
                            <div class="ni-text" (click)="gotoDetails(newsr.mainUrl)">
                                <h5 class="bfcolorGrey"><a [routerLink]="" class="foottertitle margin12" (click)="gotoDetails(newsr.mainUrl)">{{newsr.til}}</a></h5>
                                <p class="margin12">{{newsr.cont}}</p>
                                <ul>
                                    <p style="color: black;" class="margin12">{{newsr.dateTime}}</p>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="pos down-arrow nav" [routerLink]="[ '/cric-news']">
                </div>
            </div>
            <div class="col-lg-4">
                <app-series-list></app-series-list>
            </div>
        </div>
    </div>
</section>
<!-- Latest Section End -->

<br/>
<!--Completed Matches-->
<section class="video-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="section-title">
                    <h3>Complete <span class="foottertitle">Matches</span></h3>
                </div>
            </div>
        </div>
        <div class="section">

            <div class="sports-slider swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let completeMatch of completeMatchesList">
                        <!-- <div class="ms-content">
                            <div class="mc-table" data-setbg="../assets/img/videos/video-1.jpg">
                                <table class="commentryDiv">
                                    <tbody class="commentryDiv">
                                        <tr class="commentryDiv">
                                            <td class="left-team commentryDiv">
                                                <img src={{completeMatch.te1i}} alt={{completeMatch.te1n}} style="width:90px;height:85px;">
                                                <h6>{{completeMatch.s1n}}</h6>
                                            </td>
                                            <td class="mt-content commentryDiv">
                                                <div class="mc-op foottertitle title_match" style="font-size: medium;"><a href="javascript:void(0)" class="foottertitle" title={{completeMatch.en}} (click)="gotoCommentry(completeMatch.eid)">{{completeMatch.en}}</a></div>
                                                <h4>VS</h4>
                                                <div class="mc-op">
                                                    <small style="font-size:small;">
                                                                <span>{{completeMatch.t1s}}</span> : <span>{{completeMatch.t2s}}</span>
                                                            </small>
                                                </div>
                                            </td>
                                            <td class="right-team commentryDiv">
                                                <img src={{completeMatch.te2i}} alt={{completeMatch.te2n}} style="width:90px;height:85px;">
                                                <h6>{{completeMatch.s2n}}</h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> -->
                        <div class="card mc-table">
                            <div class="card-body cursorPointer">
                                <div class="container">
                                    <!-- <div class="mc-op foottertitle title_match" style="font-size: medium;"><a href="javascript:void(0)" class="foottertitle" (click)="gotoCommentry(completeMatch.eid)">{{completeMatch.en}}</a></div> -->
                                    <div class="mc-op foottertitle" style="font-size: medium;"><a href="javascript:void(0)" class="commentryDatetime dateTime" (click)="gotoCommentry(completeMatch.eid)">{{completeMatch.ed | dateFormate}}</a> <span class="spandatecompletmatches">{{completeMatch.et}}</span></div>
                                    <div class="row mt-content align-items-center">
                                        <div class="col-5 col-sm-5 left-team ">
                                            <div style="max-width: 160px;max-height: 80px;">
                                            <img src={{completeMatch.te1i}} alt={{completeMatch.te1n}} class="homeimg">
                                        </div>
                                            <h6 style="color: #c5141f; font-weight: 500;" class="teamNameHome">{{completeMatch.te1n}}</h6>
                                            <div class="mc-op" style="color: #c5141f;font-weight: bolder;"><small style="font-size:small;"><span>{{completeMatch.t1s}}</span></small></div>
                                        </div>
                                        <div class="col-2 col-sm-2 mt-content d-flex align-items-center" (click)="gotoCommentry(completeMatch.eid)">
                                            <div class="vs-image-content"><svg _ngcontent-ofp-c75="" width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M0.613599 41.3664C0.274718 41.0164 0.274718 40.449 0.613599 40.0991L6.26718 34.2608C6.60606 33.9109 7.1555 33.9109 7.49438 34.2608C7.83326 34.6108 7.83326 35.1782 7.49438 35.5281L1.8408 41.3664C1.50192 41.7163 0.95248 41.7163 0.613599 41.3664Z" fill="#897800"></path><path _ngcontent-ofp-c75="" fill-rule="evenodd" clip-rule="evenodd" d="M35.348 7.73919C35.0091 7.38924 35.0091 6.82185 35.348 6.4719L41.0016 0.633645C41.3404 0.283695 41.8899 0.283694 42.2288 0.633645C42.5676 0.983596 42.5676 1.55098 42.2288 1.90093L36.5752 7.73919C36.2363 8.08914 35.6869 8.08914 35.348 7.73919Z" fill="#897800"></path><path _ngcontent-ofp-c75="" d="M37.5518 19.5002C37.5518 28.82 30.4388 36.3751 21.6646 36.3751C12.8903 36.3751 5.77734 28.82 5.77734 19.5002C5.77734 10.1805 12.8903 2.62529 21.6646 2.62529C30.4388 2.62529 37.5518 10.1805 37.5518 19.5002Z" fill="#c5141f"></path><path _ngcontent-ofp-c75="" d="M20.4233 14.0316L16.5233 22.469L12.6564 14.0316H10.3164L15.3947 24.969H17.519L22.5808 14.0316H20.4233Z" fill="#fbfbfb"></path><path _ngcontent-ofp-c75="" d="M27.4079 25.1253C30.5944 25.1253 32.1046 23.6253 32.1046 21.8753C32.1046 17.8597 25.2173 19.3753 25.2173 17.0472C25.2173 16.219 25.9475 15.5628 27.7399 15.5628C28.7522 15.5628 29.8973 15.844 30.9429 16.4222L31.6233 14.8441C30.611 14.2191 29.1505 13.8753 27.7565 13.8753C24.5701 13.8753 23.0764 15.3753 23.0764 17.1409C23.0764 21.2034 29.9803 19.6565 29.9803 22.0159C29.9803 22.8284 29.2169 23.4378 27.4079 23.4378C25.9807 23.4378 24.5037 22.9378 23.5743 22.2346L22.8275 23.8128C23.79 24.594 25.599 25.1253 27.4079 25.1253Z" fill="#fbfbfb"></path></svg></div>
                                        </div>
                                        <div class="col-5 col-sm-5 right-team">
                                            <div style="max-width: 160px;max-height: 80px;">
                                            <img src={{completeMatch.te2i}} alt={{completeMatch.te2n}} class="homeimg">
                                        </div>
                                            <h6 style="color: #c5141f;font-weight: 500;" class="title_match">{{completeMatch.te2n}}</h6>
                                            <div class="mc-op" style="color: #c5141f;font-weight: bolder;"><small style="font-size:small;"><span>{{completeMatch.t2s}}</span></small></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-nav-wrapper">
                    <div class="swiper-button-next swiper-button-white"></div>
                    <div class="swiper-button-prev swiper-button-white"></div>
                </div>
            </div>

            <!-- Slider main container -->
            <!-- <div class="match-slider swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" *ngFor="let completeMatch of completeMatchesList">
                        <div class="ms-content">
                            <div class="mc-table" data-setbg="../assets/img/videos/video-1.jpg">
                                <table class="commentryDiv">
                                    <tbody class="commentryDiv">
                                        <tr class="commentryDiv">
                                            <td class="left-team commentryDiv">
                                                <img src={{completeMatch.te1i}} alt={{completeMatch.te1n}} style="width:90px;height:85px;">
                                                <h6>{{completeMatch.s1n}}</h6>
                                            </td>
                                            <td class="mt-content commentryDiv">
                                                <div class="mc-op foottertitle title_match" style="font-size: medium;"><a href="javascript:void(0)" class="foottertitle" title={{completeMatch.en}} (click)="gotoCommentry(completeMatch.eid)">{{completeMatch.en}}</a></div>
                                                <h4>VS</h4>
                                                <div class="mc-op">
                                                    <small style="font-size:small;">
                                                        <span>{{completeMatch.t1s}}</span> : <span>{{completeMatch.t2s}}</span>
                                                    </small>
                                                </div>
                                            </td>
                                            <td class="right-team commentryDiv">
                                                <img src={{completeMatch.te2i}} alt={{completeMatch.te2n}} style="width:90px;height:85px;">
                                                <h6>{{completeMatch.s2n}}</h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-nav-wrapper">
                    <div class="swiper-button-prev swiper-button-white"></div>
                    <div class="swiper-button-next swiper-button-white"></div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<div class="search-model">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="search-close-switch"><i class="fa fa-close"></i></div>
        <form class="search-model-form">
            <input type="text" id="search-input" placeholder="Search here.....">
        </form>
    </div>
</div>

<!-- <div  id="mainPopImage" class="modal open" tabindex="0" style="z-index: 1003; display: block; opacity: 1; top: 10%; transform: scaleX(1) scaleY(1);">
    <a  href="javascript:void(0)" class="modal-close right" data-dismiss="modal">
      <i  aria-hidden="true" class="fa fa-times"></i>
    </a>
    <div  class="modal-content">
      <div  class="modal-body">
        <a  href="javascript:void(0)" class="popup-image hide-on-small-only web-popup hide" style="display: none;">
          <img  alt="" src="https://uatb2c.cloudd.live/assets/images/afterlogin-popup.png" class="img-responsive">
        </a>
        <a  href="javascript:void(0)" class="popup-image mobile-popup">
          <img  alt="" src="https://uatb2c.cloudd.live/assets/images/afterlogin-popup.png" class="img-responsive">
        </a>
      </div>
    </div>
  </div> -->
  
  <!-- Modal -->
  <div id="mainPopImage" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false" style="background-color: rgba(26, 25, 25, 0.8);">
    <div class="modal-dialog modal-dialog-centered modaldilog_custompopup modal_customwith" role="document">
      <div class="modal-content">
        <div class="modal-header modalheader_custompopup">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        <div class="modal-body" style="padding: 0px;">
            <a  href="javascript:void(0)" class="popup-image web-popup cutopmModalPops">
                <img  alt="" src="/assets/img/afterlogin-popup.png" class="img-responsive">
              </a>
              <a  href="javascript:void(0)" class="popup-image mobile-popup cutopmModalPops">
                <img  alt="" src="/assets/img/afterlogin-mobile-popup.png" class="img-responsive">
              </a>
        </div>
      </div>
    </div>
  </div>
<app-footer></app-footer>
<router-outlet></router-outlet>